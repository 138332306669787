import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Messaggi, MessaggiAdmin } from 'src/app/_interfaces/messaggi';
import { MessaggiPreventiviService } from 'src/app/_services/messaggi-preventivi.service';

@Component({
  selector: 'app-chat-preventivi',
  templateUrl: './chat-preventivi.component.html',
  styleUrls: ['./chat-preventivi.component.scss']
})
export class ChatPreventiviComponent implements OnInit {

  @Input() ruoloUtente!: string;
  @Input() idUtente!: number;
  @Input() idPreventivo!: string;

  chat: Messaggi[];
  chatAdmin: MessaggiAdmin[];
  messageChat: string;
  messageChatAdmin: string;
  display = false;
  public originalTextAdmin: string = "";
  public originalText: string = "";
  constructor(
    private chatSrv: MessaggiPreventiviService,
    private messageSrv: MessageService,
  ) { }

  ngOnInit(): void {
    this.refreshChat(this.idPreventivo)
    this.refreshChatAdmin(this.idPreventivo)
  }

  refreshChat(idPrev: string,): void {
    this.chatSrv.getMessaggiByPreventivo(idPrev).subscribe((res) => {
      const convertToDate = (dataString: string): Date => {
        return new Date(dataString);
      };
      const sortedData = res.data.sort((a: any, b: any) => {
        return convertToDate(b.msg_data_inserimento).getTime() - convertToDate(a.msg_data_inserimento).getTime();
      });

      this.chat = [...sortedData];
    });
  }

  refreshChatAdmin(idPrev: string,): void {
    this.chatSrv.getMessaggiAdminByPrev(idPrev).subscribe((res) => {
      const convertToDate = (dataString: string): Date => {
        return new Date(dataString);
      };
      const sortedData = res.data.sort((a: any, b: any) => {
        return convertToDate(b.msga_data).getTime() - convertToDate(a.msga_data).getTime();
      });
      this.chatAdmin = [...sortedData];
    });
  }

  MessaggiLetti(): void {
    let msg: Messaggi = {}
    msg.msg_lavoro = Number(this.idPreventivo);
    msg.msg_admin_letto = Number(this.idUtente);
    msg.msg_letto = 0;
    msg.msg_ruolo = this.ruoloUtente;
    this.chatSrv.updMessaggiLetti(msg).subscribe((res) => {
      this.messageSrv.add({
        key: "tst",
        severity: res.res === "ok" ? "success" : "danger",
        summary: `Messaggio`,
        detail: res.message,
        life: 8000 // Tempo in millisecondi (10 secondi)
      });
      this.refreshChat(this.idPreventivo)
    });
  }

  MessaggiLettiSerramentista(): void {
    let msg: Messaggi = {}
    msg.msg_lavoro = Number(this.idPreventivo);
    msg.msg_admin_letto = 0;
    msg.msg_letto = 1;
    msg.msg_ruolo = this.ruoloUtente;
    this.chatSrv.updMessaggiLetti(msg).subscribe((res) => {
      this.messageSrv.add({
        key: "tst",
        severity: res.res === "ok" ? "success" : "danger",
        summary: `Messaggio`,
        detail: res.message,
        life: 8000 // Tempo in millisecondi (10 secondi)
      });
      this.refreshChat(this.idPreventivo)
    });
  }

  VerificaMessaggioAdminSerramentista(idserr: number): string {
    if (this.ruoloUtente == "SE") {
      if (idserr == Number(this.idUtente)) {
        return 'message-own'
      } else {
        return 'message-from'
      }
    } else {
      if (idserr == Number(this.idPreventivo)) {
        return 'message-own'
      } else {
        return 'message-from'
      }
    }

  }

  VerificaMessaggioNonLetto(isadmin: number, letto: number, admin_letto: number): string {
    if (this.ruoloUtente === 'SE') {
      if (isadmin != 0 && letto == 0) {
        return 'font-weight: bold; font-style: italic; color: #00ff00;'
      } else {
        return ''
      }
    }
    if (this.ruoloUtente === 'AD') {
      if (isadmin == 0 && admin_letto == 0) {
        return 'font-weight: bold; font-style: italic; color: #00ff00;'
      } else {
        return ''
      }
    }
  }

  MessaggioNONLEtto(idMessage: number): void {
    let msg: Messaggi = {}
    msg.msg_id = idMessage;
    msg.msg_admin_letto = 0;
    msg.msg_letto = 0;
    msg.msg_ruolo = 'AD'
    this.chatSrv.updMessaggioNONLetto(msg).subscribe((res) => {
      this.messageSrv.add({
        key: "tst",
        severity: res.res === "ok" ? "success" : "danger",
        summary: `Messaggio`,
        detail: res.message,
        life: 8000 // Tempo in millisecondi (10 secondi)
      });
      this.refreshChat(this.idPreventivo)
    });
  }

  delMessaggio(idMessage: number): void {
    let msg: Messaggi = {}
    msg.msg_id = idMessage;
    msg.msg_admin_letto = Number(this.idUtente);
    msg.msg_canc = 1;
    this.chatSrv.cancMessaggio(msg).subscribe((res) => {
      this.messageSrv.add({
        key: "tst",
        severity: res.res === "ok" ? "success" : "danger",
        summary: `Messaggio`,
        detail: res.message,
        life: 8000 // Tempo in millisecondi (10 secondi)
      });
      this.refreshChat(this.idPreventivo)
    });
  }

  sendMessage(): void {
    let msg: Messaggi = {}
    msg.msg_id = 0;
    msg.msg_lavoro = Number(this.idPreventivo);
    msg.msg_serramentista = Number(this.idUtente);
    if (this.ruoloUtente === 'AD') {
      msg.msg_is_admin = 1;
    } else {
      msg.msg_is_admin = 0;
    }
    msg.msg_testo = this.messageChat;
    msg.msg_admin_letto = 0;
    this.chatSrv.addMessaggio(msg).subscribe((res) => {
      this.messageSrv.add({
        key: "tst",
        severity: res.res === "ok" ? "success" : "danger",
        summary: `Messaggio`,
        detail: res.message,
        life: 8000 // Tempo in millisecondi (10 secondi)
      });
      this.refreshChat(this.idPreventivo)
    });
  }

  sendMessageAdmin(): void {
    let msg: MessaggiAdmin = {}
    msg.msga_id = 0;
    msg.msga_lavoro = Number(this.idPreventivo);
    msg.msga_admin = sessionStorage.getItem("name");
    msg.msga_testo = this.messageChatAdmin;
    this.chatSrv.addMessaggioAdminPrev(msg).subscribe((res) => {
      this.messageSrv.add({
        key: "tst",
        severity: res.res === "ok" ? "success" : "danger",
        summary: `Messaggio`,
        detail: res.message,
        life: 8000 // Tempo in millisecondi (10 secondi)
      });
      this.refreshChatAdmin(this.idPreventivo)
    });
  }


  saveMessage(msg: any) {
    msg.inEditMode = false;
    this.chatSrv.addMessaggio(msg).subscribe((res) => {
      this.messageSrv.add({
        key: "tst",
        severity: res.res === "ok" ? "success" : "danger",
        summary: `Messaggio`,
        detail: res.message,
      });
      this.refreshChatAdmin(this.idPreventivo)
    });
  }

  saveMessageAdmin(msg: any) {
    msg.inEditMode = false;
    this.chatSrv.addMessaggioAdminPrev(msg).subscribe((res) => {
      this.messageSrv.add({
        key: "tst",
        severity: res.res === "ok" ? "success" : "danger",
        summary: `Messaggio`,
        detail: res.message,
      });
      this.refreshChatAdmin(this.idPreventivo)
    });
  }

  cancelEdit(msg: any) {
    msg.inEditMode = false;
    msg.msg_testo = this.originalText;
  }

  editMessageAdmin(msg: any) {
    msg.inEditMode = true;
    this.originalTextAdmin = msg.msga_testo
  }

  editMessage(msg: any) {
    msg.inEditMode = true;
    this.originalText = msg.msg_testo
  }
  cancelEditAdmin(msg: any) {
    msg.inEditMode = false;
    msg.msga_testo = this.originalTextAdmin;
  }


}
