import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Scadenza } from '../_interfaces/serramentista';

@Injectable({
  providedIn: 'root'
})
export class ScadenzeService {

  private baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) {
  }

  getAll(id: string): Observable<{ data: Scadenza[] }> {
    return this.http.get(`${this.baseUrl}/scadenze`).pipe(
      map((res: { data: Scadenza[] }) => res)
    );
  }

  getById(id: string): Observable<{ data: Scadenza[] }> {
    return this.http.get(`${this.baseUrl}/scadenze/${id}`).pipe(
      map((res: { data: Scadenza[] }) => res)
    );
  }

  getByIdSerr(id: string): Observable<{ data: Scadenza[] }> {
    return this.http.get(`${this.baseUrl}/scadenze/by_serr/${id}`).pipe(
      map((res: { data: Scadenza[] }) => res)
    );
  }

  add(mb: Scadenza): Observable<{ res: string, message: string }> {
    return this.http.post(`${this.baseUrl}/scadenze/add`, mb).pipe(
      map((res: { res: string, message: string }) => res));
  }

  del(id: number): Observable<{ res: string, message: string }> {
    return this.http.post(`${this.baseUrl}/scadenze/del`, { "doc_id": id }).pipe(
      map((res: { res: string, message: string }) => res));
  }

}
